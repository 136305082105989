<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Type</label>
                          <v-select
                            v-model="type"
                            label="name"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None"
                            :options="[
                              { name: 'Alloted', id: 'Assigned' },
                              { name: 'Pending', id: 'Pending' },
                            ]"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- <b-col md="4">
                          <b-form-group>
                            <label>Branch</label>
                            <v-select v-model="branch" label="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None" :options="['Channel Partner','Franchise','Admin']" @input="getEmployee()"
                            />
                          </b-form-group>
                        </b-col> -->
                      <b-col md="4">
                        <b-form-group>
                          <label>Employee</label>
                          <v-select
                            v-model="employee"
                            label="fullname"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None"
                            :options="employeeOptions"
                          >
                            <template
                              #option="{ fullname, profile_image, username, surname }"
                            >
                              <b-avatar
                                v-if="fullname != 'All'"
                                :src="getprofileImage(profile_image)"
                              />
                              <span class="font-weight-bolder"> {{ fullname }}</span
                              ><span v-if="fullname != 'All'">({{ username }})</span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <!-- <b-col md="4">
                          <b-form-group>
                            <label>Employee Assets</label>
                            <v-select v-model="employee" label="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None" :options="employeeOptions"/>
                          </b-form-group>
                        </b-col> -->
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="searchData">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <report-table :data="data" :columns="fields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import moment from "moment";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch,
  },
  data() {
    return {
      type: "",
      employee: "",
      employeeOptions: [],
      branch: "",
      startdate: "",
      enddate: "",
      result: "",
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      data1: [],
      fields: [
        { field: "id", label: "S.R. No" },
        { field: "employee", label: "Employee Name" },
        { field: "branch", label: "Branch" },
        { field: "department", label: "Department" },
        { field: "date", label: "Date" },
        { field: "product", label: "Product Name" },
        { field: "make", label: "Make" },
        { field: "status", label: "Status" },
        { field: "amount", label: "Amount" },
        { field: "total", label: "Total" },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async searchData() {
      const data = {
        type: this.type.id,
        employee_id: this.employee.id,
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/assetsreport`,
      })
        .then((response) => {
          this.data = response.data.data;
          this.data.map((item) => {
            if (item.length > 0) {
              item.map((inner) => {
                if (inner.length > 0) {
                  inner.map((mostinner) => {
                    this.data.product = mostinner.product;
                  });
                }
              });
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    init() {
      this.getEmployee();
    },
    async getEmployee(id) {
      this.employeeOptions = [];
      // let url = `getPositionWiseEmployee/${id}`
      // if(id == 'all'){
      //   url='getEmployee'
      // }
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      }).then((response) => {
        this.employeeOptions = response.data.data;
        this.employeeOptions.unshift({ fullname: "All", id: 0 });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
